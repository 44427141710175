<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="contract:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.contract"
                  placeholder="Subject of contract"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD:" class="mb5">
                <group-select
                  v-model="filterForm.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="source：" class="mb5">
                <group-select
                  v-model="filterForm.sourceIdList"
                  :data-source="sourcesList"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select Source"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="adv:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.advertisers"
                  placeholder=" advertisers"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="legalAdv:" class="mb5">
                <el-input
                  clearable
                  v-model="filterForm.advertisersLegalName"
                  placeholder="adv LegalName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="cycle(B):" class="mb5">
                <el-select
                  v-model="filterForm.businessCycle"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option
                    v-for="item in businessCycleList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="payment(P):" class="mb5">
                <el-select
                  v-model="filterForm.paymentCycle"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option
                    v-for="item in paymentCycleList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="payment(A):" class="mb5">
                <el-select
                  v-model="filterForm.accountsReceivable"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in accountList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="type:" class="mb5">
                <el-select
                  v-model="filterForm.paymentType"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in typeList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
                <el-button type="primary" @click="openAddDialog">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          stripe
          border
          height="780"
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          :row-style="{ height: '50px' }"
          @sort-change="sortChange"
        >
          <el-table-column
            label="Subject of contract"
            prop="contract"
            align="center"
          ></el-table-column>
          <el-table-column label="BD" prop="business" align="center"></el-table-column>
          <el-table-column label="SourceName" prop="sourceName" align="center"></el-table-column>
          <el-table-column
            label="BD Group"
            prop="businessType"
            align="center"
            :key="40"
          ></el-table-column>
          <el-table-column
            label="Advertiser name"
            prop="advertisers"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Advertiser Legal Name"
            prop="advertisersLegalName"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="Cycle Length of Business"
            prop="businessCycle"
            align="center"
          ></el-table-column>
          <el-table-column
            label="Net/Payment Period"
            prop="paymentCycle"
            align="center"
          ></el-table-column>
          <el-table-column
            label="Account for Payment"
            prop="accountsReceivable"
            align="center"
          ></el-table-column>
          <el-table-column label="Type" prop="paymentType" align="center"></el-table-column>
          <el-table-column label="SignTime" prop="signTime" align="center"></el-table-column>
          <el-table-column label="IO" prop="fileUrl" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <span @click="openFile(scope.row)" class="cor337ab7">{{ scope.row.fileUrl }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Email" prop="email" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formatEmail(scope.row.email) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Address"
            prop="address"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                size="mini"
                @click="openDialog(scope.row)"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                size="mini"
                @click="deleteItem(scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

        <!--  编辑模态框-->
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="40%">
          <el-form :model="editData" size="small" ref="editForm" label-width="auto" :rules="rules">
            <el-form-item label="Subject of contract：" prop="contract">
              <el-select
                v-model="editData.contract"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in contractList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Source:" prop="sourceId">
              <group-select
                v-model="editData.sourceId"
                :data-source="sourcesList"
                :multiple="false"
                :loading="sourceLoading"
                @change="handleSourceEditChange"
                reserve-keyword
                clearable
                filterable
                collapse-tags
                placeholder="Please select Source"
                class="w100"
              />
            </el-form-item>
            <el-form-item label="BD：" prop="business">
              <el-input v-model="editData.business" :disabled="true" />
            </el-form-item>
            <el-form-item label="Advertiser name：" prop="advertisers">
              <el-input
                v-model="editData.advertisers"
                placeholder="Please enter advertisers"
              ></el-input>
            </el-form-item>

            <el-form-item label="Advertiser Legal name：" prop="advertisersLegalName">
              <el-input
                v-model="editData.advertisersLegalName"
                placeholder="Please enter advertisersLegalName"
              ></el-input>
            </el-form-item>
            <el-form-item label="Cycle length of business：" prop="businessCycle">
              <el-select
                v-model="editData.businessCycle"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option
                  v-for="item in businessCycleList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Net/Payment period：" prop="paymentCycle">
              <el-select
                v-model="editData.paymentCycle"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in paymentCycleList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Account for payment：" prop="accountsReceivable">
              <el-select
                v-model="editData.accountsReceivable"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in accountList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Type：" prop="paymentType">
              <el-select
                v-model="editData.paymentType"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in typeList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="signTime" class="mb10,mt10" prop="signTime">
              <el-date-picker
                v-model="editData.signTime"
                value-format="yyyy-MM-dd"
                placeholder="signTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="IO：" prop="fileUrl" style="margin-bottom: 20px">
              <el-upload
                class="upload-demo"
                action="/api/finance/import/file"
                :before-upload="beforeUpload"
                :on-success="handleUploadEdit"
                :file-list="fileList"
                :auto-upload="true"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="address" prop="address">
              <el-input v-model="editData.address" placeholder="Please enter address"></el-input>
            </el-form-item>
            <el-form-item
              v-for="(email, index) in editData.emails"
              :label="'email-' + index"
              :key="email.key"
              :prop="'emails.' + index + '.value'"
              :rules="{
                required: true,
                message: 'email is null',
                trigger: 'blur',
              }"
            >
              <el-input v-model="email.value" style="width: 85%"></el-input>
              <el-button type="danger" size="mini" @click.prevent="removeEditDomain(email)"
                >删除</el-button
              >
            </el-form-item>
            <el-form-item label="not auto merge" prop="notAutoMerge">
              <el-switch
                v-model="editData.notAutoMerge"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </el-form-item>
            <el-form-item label="remark：" prop="remark">
              <el-input
                type="textarea"
                v-model="editData.remark"
                maxlength="500"
                placeholder="Please enter remark"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialog.visible = false">取 消</el-button>
            <el-button @click="addDomain('edit')">新增email</el-button>
            <el-button type="primary" @click="editForm('editForm')">确 定</el-button>
          </span>
        </el-dialog>

        <!--  新增模态框-->
        <el-dialog :title="addDialog.title" :visible.sync="addDialog.visible" width="40%">
          <el-form
            :model="contractData"
            size="small"
            ref="addForm"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="Subject of contract：" prop="contract">
              <el-select
                v-model="contractData.contract"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in contractList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Source:" prop="sourceId">
              <group-select
                v-model="contractData.sourceId"
                :data-source="sourcesList"
                :multiple="false"
                :loading="sourceLoading"
                @change="handleSourceChange"
                reserve-keyword
                clearable
                filterable
                collapse-tags
                placeholder="Please select Source"
                class="w100"
              />
            </el-form-item>
            <el-form-item label="BD：" prop="business">
              <el-input v-model="contractData.business" :disabled="true" />
            </el-form-item>
            <el-form-item label="Advertiser name：" prop="advertisers">
              <el-input
                v-model="contractData.advertisers"
                placeholder="Please enter advertisers"
              ></el-input>
            </el-form-item>

            <el-form-item label="Advertiser Legal name：" prop="advertisersLegalName">
              <el-input
                v-model="contractData.advertisersLegalName"
                placeholder="Please enter advertisersLegalName"
              ></el-input>
            </el-form-item>
            <el-form-item label="Cycle length of business：" prop="businessCycle">
              <el-select
                v-model="contractData.businessCycle"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option
                  v-for="item in businessCycleList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Net/Payment period：" prop="paymentCycle">
              <el-select
                v-model="contractData.paymentCycle"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in paymentCycleList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Account for payment：" prop="accountsReceivable">
              <el-select
                v-model="contractData.accountsReceivable"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in accountList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Type：" prop="paymentType">
              <el-select
                v-model="contractData.paymentType"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
                clearable
              >
                <el-option v-for="item in typeList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="signTime" style="margin-bottom: 20px" prop="signTime">
              <el-date-picker
                v-model="contractData.signTime"
                value-format="yyyy-MM-dd"
                placeholder="signTime"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="IO：" prop="fileUrl" style="margin-bottom: 20px">
              <el-upload
                class="upload-demo"
                action="/api/finance/import/file"
                :before-upload="beforeUpload"
                :on-success="handleUploadSuccess"
                :file-list="fileList"
                :auto-upload="true"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="address" prop="address">
              <el-input
                v-model="contractData.address"
                placeholder="Please enter address"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-for="(email, index) in contractData.emails"
              :label="'email-' + index"
              :key="email.key"
              :prop="'emails.' + index + '.value'"
              :rules="{
                required: true,
                message: 'email is null',
                trigger: 'blur',
              }"
            >
              <el-input v-model="email.value" style="width: 85%"></el-input>
              <el-button type="danger" size="mini" @click.prevent="removeDomain(email)"
                >删除</el-button
              >
            </el-form-item>
            <el-form-item label="not auto merge" prop="notAutoMerge">
              <el-switch
                v-model="contractData.notAutoMerge"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </el-form-item>
            <el-form-item label="remark：" prop="remark">
              <el-input
                type="textarea"
                maxlength="500"
                v-model="contractData.remark"
                placeholder="Please enter remark"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="addDialog.visible = false">取 消</el-button>
            <el-button @click="addDomain('add')">新增email</el-button>
            <el-button type="primary" :loading="loading.submitBtn" @click="add">确 定</el-button>
          </span>
        </el-dialog>

        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import contract from '../controllers/finance/contract';
  export default {
    name: 'contract',
    ...contract,
  };
</script>

<style>
  .table-row {
    height: 50px;
  }
</style>
